<template>
  <div class="lottery-container">
    <!-- 抽奖页面头部 -->
    <div class="lottery-header row-between">
      <div
        style="margin-left: 1.9063rem;"
        class="row-center"
      >
        <img
          class="lottery-header-logo"
          src="../assets/icon/lottery/logo.png"
        />
      </div>
      <p
        class="lottery-header-tip"
        style="margin-right: 1.4375rem;word-spacing: -1px;"
      >艺 术 的 理 想 国 · 我 们 的 家</p>
    </div>
    <!-- 抽奖页面展示 -->
    <div class="lottery-body col-center">
      <p
        class="lottery-header-size"
        style="margin-right: 1.4375rem;word-spacing: -1px;"
      >元宇宙中秋</p>
      <p
        class="lottery-header-size"
        style="margin-right: 1.4375rem;word-spacing: -1px;"
      >抽取实物好礼</p>

      <!-- <img
        class="lottery-body-title-logo"
        src="../assets/icon/lottery/title-font-logo.png"
      /> -->
      <img
        style="width:85.6%;height: auto;"
        src="../assets/icon/lottery/lottery-demo.png"
      />
      <div class="lottery-date">
        <p class="lottery-id-title"> {{ lotteryStatus ? '' : startTime }}</p>
        <img
          v-if="lotteryStatus"
          style="height:auto;width:89.6%;"
          @click="goDetail('rule')"
          :src="require(`../assets/icon/lottery/lottery.png`)"
        />
        <img
          v-else
          style="height:auto;width:89.6%;"
          :src="require(`../assets/icon/lottery/no.png`)"
        />
      </div>
      <!-- <div class="lottery-rule row-center">
        <a @click="goDetail('rule')">获奖名单</a>
        <img
          @click="goDetail('rule')"
          src="../assets/icon/lottery/next.png"
        />
        <div class="line"></div>
        <a @click="goDetail('code')">抽奖代码公示</a>
        <img
          @click="goDetail('code')"
          src="../assets/icon/lottery/next.png"
        />
      </div> -->
      <div class="block-list">
        <div class="block-list-title row-center"></div>
        <div class="now-block-container">
        </div>
        <!-- <el-table :data="blockList">
          <el-table-column
            prop="blockName"
            label="目标区块"
          >
          </el-table-column>
          <el-table-column
            prop="blockHeight"
            label="区块高度"
          >
            <template slot-scope="scope">
              <p>{{scope.row.blockHeight | monetyAns}}</p>
            </template>
          </el-table-column>
          <el-table-column label="区块哈希">
            <template slot-scope="scope">
              <div
                @click="goExplorerDetail(scope.row.blockHeight,scope.row.blockHash)"
                class="block-detail row-between"
              >
                <p>{{scope.row.blockHash ? scope.row.blockHash : '等待生成'}}</p>
                <img
                  v-if="scope.row.blockHash"
                  src="../assets/icon/lottery/next.png"
                />
              </div>
            </template>
          </el-table-column>
        </el-table> -->
        <div class="lottery-font-title">1、元宇宙中秋活动期间，用户参与抓捕玉兔行动，每消耗一次抓捕玉兔机会便可自动获得一次参与最终实物抽奖的机会。
          <br />
          注：<br />（1）抽奖次数可根据消耗的抓捕次数叠加。<br />
          （2）抓捕成功或失败均会增加抽奖次数。
          玉兔抓捕机会获取途径可查看Meta彼岸公众号：《月升彼岸 | 元宇宙中秋即将拉开帷幕》。
        </div>
        <div class="lottery-font-title">
          2、抽奖规则： <br />
          （1）为保证本次用户参与抽奖活动的公正性，官方将参与抓捕玉兔活动用户的抽奖基数与总奖品份数进行随机配组，作为本次活动奖品最终中奖的抽选方式。<br />
          （2）活动期间用户所消耗的抓捕玉兔次数将作为参与此次抽奖活动的的抽选基数，消耗的抓捕玉兔次数越多，中奖概率越大。<br />
          3、本次实物抽奖活动奖品共分五档：<br />
          一等奖：PS5游戏机 1台 单价4528元<br />
          二等奖：VIFA音响 2台 单价3380元<br />
          三等奖：Pico Neo 3 3台 单价2499元<br />
          四等奖：观月望远镜 6台 单价578元<br />
          五等奖：拍拍月球灯 50个 单价88元<br />
          4、抽奖时间及奖品发放：<br />
          抽奖时间：2022年9月26日 18:00:00 <br />
          官方将在抽奖结束后公布中奖名单，五个工作日内通过中奖账户在Meta彼岸所注册的手机号与中奖用户联系，请您保持电话畅通。<br />
          注：本次抽奖活动最终解释权归Meta彼岸所有。</div>
        <div style="margin-top:10px"></div>
      </div>
      <div
        class="block-list"
        style="overflow: hidden;min-height: 15.75rem;"
      >
        <div class="block-last-title row-center"></div>
        <div class="now-block-container"></div>
        <swiper
          class="my-swipe"
          style="width:400px"
          ref="banner"
          :options="seriesOption"
        >
          <swiper-slide
            v-for="(item,index) in prizeList"
            :key="index"
          >
            <div style="width:7.5625rem;height:10.5625rem;text-align: center;">
              <img
                style="width:100%;height:75%;object-fit:contain"
                :src="item.img"
                alt=""
              >
              <p style="color: #f3cdb6;margin-top:10px">{{item.title}}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <p class="lottery-footer-tip">Copyright © Genimeta Ltd. All rights reserved</p>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import api from '../api/index-client'
import config from '../config'
import { Toast } from 'vant';
import { Table,TableColumn } from 'element-ui';
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Toast)

export default {
  data () {
    return {
      lotteryStatus: true,
      lotteryId: '10040005600519',
      startTime: '',
      nowBlockHeight: '5454225',
      blockList: [],
      seriesOption: {
        autoplay: true,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        slidesPerView: 3,
        clicks: {
          preventClicksPropagation: true,
        },
        centeredSlides: true,
      },
      prizeList: [
        {
          img: require('../assets/icon/lottery/1.png'),
          title: 'PS5游戏机'
        },
        {
          img: require('../assets/icon/lottery/2.png'),
          title: 'VIFA音响'
        },
        {
          img: require('../assets/icon/lottery/3.png'),
          title: 'Pico Neo 3'
        },
        {
          img: require('../assets/icon/lottery/4.png'),
          title: '观月望远镜'
        },
        {
          img: require('../assets/icon/lottery/5.png'),
          title: '拍拍月球灯'
        },
      ]
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.getLotteryInfo() // 获取抽奖信息
  },
  methods: {
    goExplorerDetail (e,rem) {
      if (!rem) {
        // window.location.href = config.explorerUrl + e7
      } else {
        window.location.href = config.explorerUrl + e + '?from=zdChain'
      }
    },
    getLotteryInfo () {
      api
        .get('lottery/detail')
        .then(result => {
          if (result.data.status == 200) {
            this.startTime = result.data.data.startTime
          }
        })
      api
        .get('lottery/getLotteryList')
        .then(result => {
          if (result.data.status == 200) {
            this.lotteryStatus = result.data.data == null ? false : true
            this.$store.commit("HIDE_APPLOADING")
          }
        })
    },
    goDetail (e) {
      if (this.lotteryStatus) {
        var route = e == 'rule' ? '/lotteryRule' : '/contractCode'
        this.$router.push(route)
      } else {
        Toast('未开奖！');
      }

    }
  },
}
</script>